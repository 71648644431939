import BaseCtrl from "./common/base/base.ctrl";
import { observable, decorate } from 'mobx';
import { api } from './common/constants';
import { storage } from './common/utilities';

class AppCtrl extends BaseCtrl {
    /**
     * Alert
     */
    alert = {};
    showAlert = (title, message, color = "info") => {
        this.alert.title = title;
        this.alert.message = message;
        this.alert.visible = true;
        this.alert.color = color;
    }
    alertDestroy = () => {
        this.alert = {};
    }

    /**
     * User
     */
    isAuthenticated = false;
    isInit = false;
    user = {};

    updateAuthenticate = value => {
        this.isAuthenticated = value;
    }

    initUser = async () => {
        this.isInit = true;
    }

    isLogout = false;
    onLogout = e => {
        e.preventDefault();
        storage.clearStorage();

        this.isLogout = true;
        this.isAuthenticated = false;
        this.isInit = false;
        this.user = {};
    }

    updateLogout = value => {
        this.isLogout = value;
    }

    isMin = !(localStorage.getItem('isMin') === true)
    toggleMin = async () => {
        this.isMin = !this.isMin
        localStorage.setItem('isMin', this.isMin)
    }
}

decorate(AppCtrl, {
    alert: observable,
    showAlert: observable,
    alertDestroy: observable,
    isInit: observable,
    initUser: observable,
    isAuthenticated: observable,
    isLogout: observable,
    updateLogout: observable,
    updateAuthenticate: observable,
    user: observable,
    isMin: observable
});

export default new AppCtrl()

