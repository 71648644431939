import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import vi from "./vi-vn"
import en from "./en-us"

const lng = 'en'

i18n
  .use(initReactI18next)
  .init({
    lng,
    resources: { vi, en },
    debug: false,
    fallbackLng: 'en',
    keySeparator: true,
    interpolation: {
      escapeValue: true, 
    },
  })


export default i18n;