import { toJS } from 'mobx';
import i18next from "i18next";
import { toast } from 'react-toastify';
import appCtrl from '../../app.ctrl'
import { translate } from '../utilities'
import {message} from 'antd'

const { Http, validator, datetime, validate, storage, convert } = require("../utilities");
const lang = i18next.language;
const moment = require('moment');

toast.configure()

export default class BaseCtrl {
    constructor(props) {
        this.http = new Http();
        this.validator = validator;
        this.lang = lang;
        this.moment = moment;
        this.datetime = datetime;
        this.toast = toast;
        this.validate = validate;
        this.convert = convert;
        this.message = message;
    }

    toJS = obj => {
        return toJS(obj)
    }
    
    showAlertError = (title, message) => {
        appCtrl.showAlert(title, message, "danger");
    }

    showAlertErrorCode = (code) => {
        let title = translate("error:" + code + ":title");
        let message = translate("error:" + code + ":message");

        appCtrl.showAlert(title, message, "danger");
    }
}