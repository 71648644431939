import i18next from "i18next";
import { translate } from "./translate.util";
import { getToken } from "./storage.util";
import http from "../constants/http.constant";
import { toast } from 'react-toastify';
import { storage } from ".";
import appCtrl from '../../app.ctrl'

const lang = i18next.language;
const dicToQuery = (obj) => {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&')
}

toast.configure()

export default class HttpUtil {
  constructor() {
    this.toast = toast;
  }

  headers = {
    "language": i18next.language,
    "Accept": "*/*",
    "Content-Type": "application/json",
  }

  /*Fetch data from API*/
  fetchData = async (url, params, method = 'GET', onlyData = true) => {
    let headers = this.headers
    const options = { method, headers }
    const token = getToken();

    if (token) {
      headers.Authorization = 'Bearer ' + token;
    }

    if (params) {
      if (method === 'GET') {
        url += '?' + dicToQuery(params)
      }
      else {
        options.body = params;
      }
    }

    let resp = await fetch(url, options);

    if (onlyData) {
      let data = await resp.json();
      if (resp.status !== http.OK) {
        appCtrl.message.error(data.message)
        return;
      }

      return await data;
    }
    else {
      if (resp.status !== http.OK) {
        let data = await resp.json();
        appCtrl.message.error(data.message)
      }
      return resp;
    }
  }

  /*get method*/
  get = async (url, params, onlyData = true) => {
    return await this.fetchData(url, params, 'GET', onlyData)
  }

  /*post method*/
  post = async (url, params, onlyData = true) => {
    return await this.fetchData(url, JSON.stringify(params), 'POST', onlyData)
  }

  /*put method*/
  put = async (url, params, onlyData = true) => {
    return await this.fetchData(url, JSON.stringify(params), 'PUT', onlyData);
  }

  /*delete method*/
  delete = async (url, params, onlyData = true) => {
    return await this.fetchData(url, JSON.stringify(params), 'DELETE', onlyData)
  }

  /*upload method*/
  upload = async (url, files) => {
    let form = new FormData()

    if (Array.isArray(files)) {
      files.forEach(file => {
        form.append('file', file);
      });
    }
    else {
      form.append('file', files)
    }
    return this.fetchData(url, form, 'POST', { Authorization: 'Bearer ' + storage.getToken() });
  }
}
