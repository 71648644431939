class Convert {
    getSelectFormat(data, label, value) {
        var temp = [];

        if (data) {
            data.forEach(e => {
                temp.push({ value: e[value], label: e[label] });
            })
        }
        return temp
    }
}

export default new Convert();