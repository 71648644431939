import app from './app.json'
import user from './user.json'
import error from './error.json'
import r3 from './r3.json'

const file = Object.assign(
  user,
  app,
  error,
  r3
)

export default file