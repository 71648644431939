const URL = process.env.REACT_APP_INFLIGHT_REPORT_API;

export default {
    user_token: `${URL}/api/v1/tools/check`,
    user_profile: `${URL}/api/v1/user/profile`,
    user_change_password: `${URL}/api/v1/user/change-password`,
    user_log: `${URL}/api/v1/user/logs`,
    admin_users: `${URL}/api/v1/user`,
    admin_user_create: `${URL}/api/v1/user`,
    admin_user_update: `${URL}/api/v1/user`,
    admin_perms: `${URL}/api/v1/user/perms`,
    admin_roles: `${URL}/api/v1/user/roles`,
    ops_jobs: `${URL}/api/v1/ops/logs`,
    cate_opening_hour: `${URL}/api/v1/categories/opening-hours`,
    cate_states: `${URL}/api/v1/categories/states`,
    cate_sos: `${URL}/api/v1/categories/sos`,
    cate_doors: `${URL}/api/v1/categories/doors`,
    cate_ports: `${URL}/api/v1/categories/ports`,
    cate_lines: `${URL}/api/v1/categories/lines`,
    cate_containers: `${URL}/api/v1/categories/containers`,
    cate_email_template: `${URL}/api/v1/categories/email-templates`,
    cate_drivers: `${URL}/api/v1/categories/drivers`,
    cate_suburb: `${URL}/api/v1/categories/suburbs`,
    cate_depots: `${URL}/api/v1/categories/depots`,
    cate_sms_templates: `${URL}/api/v1/categories/sms-templates`,
    cate_tanks: `${URL}/api/v1/categories/tanks`,
    cate_vessels: `${URL}/api/v1/categories/vessels`,
    cate_post_codes: `${URL}/api/v1/categories/post-codes`,
    cate_brokers: `${URL}/api/v1/categories/brokers`,
    cate_clients: `${URL}/api/v1/categories/clients`,
    cate_delivery_address: `${URL}/api/v1/categories/delivery-address`,
    cate_delivery_address_list: `${URL}/api/v1/categories/clients/{clientId}/delivery-address`,
}