const KEY_TOKEN = 'access_token';
const KEY_PERM = 'permissions';
const PROFILE_KEY = 'profile';
const KEY_LANGUAGE = 'language';

/** user */
export const setUser = (user) => { sessionStorage.setItem('user', JSON.stringify(user)) }
export const getUser = () => { return JSON.parse(sessionStorage.getItem('user')) || null }

/** language */
export const getLanguage = () => { return JSON.parse(localStorage.getItem(KEY_LANGUAGE)) || null }
export const setLanguage = (lang = 'vi') => { localStorage.setItem(KEY_LANGUAGE, JSON.stringify(lang)) }
export const removeLanguage = () => { localStorage.removeItem(KEY_LANGUAGE) }

/** access token */
export const getToken = () => { return JSON.parse(sessionStorage.getItem(KEY_TOKEN)) || null }
export const setToken = (token = '') => { sessionStorage.setItem(KEY_TOKEN, JSON.stringify(token)) }
export const removeToken = () => { sessionStorage.removeItem(KEY_TOKEN) }

/** permissions */
export const getPerm = () => { return JSON.parse(sessionStorage.getItem(KEY_PERM)) || null }
export const setPerm = (perm = '') => { sessionStorage.setItem(KEY_PERM, JSON.stringify(perm)) }
export const removePerm = () => { sessionStorage.removeItem(KEY_PERM) }

export const clearStorage = () => { sessionStorage.clear() }