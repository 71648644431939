const moment = require('moment');

const DEFAULT_DATETIME_FORMAT = "DD-MM-YYYY HH:mm:ss";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";

class Datetime {
  getFormat(date, format = DEFAULT_DATETIME_FORMAT) {
    if (date) return moment(date).utc(false).format(format)
    else return null
  }

  getFormatDate(date, format = DEFAULT_DATE_FORMAT) {
    if (date) return moment(date).utc(false).format(format)
    else return null
  }

  getFormatTime(date, format = DEFAULT_TIME_FORMAT) {
    if (date) return moment(date).utc(false).format(format)
    else return null
  }
}

export default new Datetime();