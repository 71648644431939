import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import './scss/style.scss';
import 'font-awesome/css/font-awesome.min.css';
import { routes } from './common/constants';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { observer } from 'mobx-react';
import appCtrl from './app.ctrl';
import { CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter, CButton } from '@coreui/react'
import i18next from 'i18next'
import { storage, translate } from './common/utilities'
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import vi from 'date-fns/locale/vi';
import ability from './common/authorize/ability'

registerLocale('vi', vi)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Login = React.lazy(() => import('./modules/user/login/login.com'));
const InsightLayout = React.lazy(() => import('./layouts/TheLayout'));

class App extends Component {
  componentDidMount() {
    const lang = storage.getLanguage();
    const perm = storage.getPerm();
    if (perm) {
      ability.update([{
        'action': 'can',
        'subject': perm
      }])
    }

    if (lang)
      i18next.changeLanguage(lang);
    else
      storage.setLanguage(i18next.language);
  }

  render() {
    return (
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path={routes.USER_LOGIN} name="Login" render={props => <Login {...props} />} />
            <Route path={routes.ROOT} name="Home" render={props => <InsightLayout {...props} />} />
          </Switch>
        </React.Suspense>
        <CModal
          color={appCtrl.alert.color}
          show={appCtrl.alert.visible}
          onClose={appCtrl.alertDestroy}
        >
          <CModalHeader closeButton>
            <CModalTitle style={{ fontFamily: 'Jambono' }}>{appCtrl.alert.title}</CModalTitle>
          </CModalHeader>
          <CModalBody> {appCtrl.alert.message} </CModalBody>
        </CModal>
        <ToastContainer
          autoClose={1000}
          position="top-center"
          role="alert"
          draggable
          closeOnClick
          transition={Slide} />
      </Router>
    );
  }
}

export default observer(App)
