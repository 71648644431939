export default {
  ROOT: "/",
  HOME: "/home",
  DASHBOARD: '/dashboard',
  ADMIN_USERS: '/admin/users',
  ADMIN_PERMS: '/admin/perms',
  ADMIN_ROLES: '/admin/roles',
  USER_LOGIN: "/tool",
  USER_DASHBOARD: "/user",
  USER_PROFILE: "/user/profile",
  USER_FORGOT_PASSWORD: "/user/forgot",
  USER_REGISTRY: "/user/registry",
  USER_LOGS: "/user/logs",
  USER_LOGS_DETAILS: "/user/logs/:id",
  USER_ROLES: "/user/roles",
  USER_CHANGEPW: "/user/change-password",
  BROKERS: '/operations/brokers',
  CLIENTS: '/operations/clients',
  TIME_SLOTS:'/operations/time-slots',
  CLIENT_YARDS: '/categories/client-yards',
  INVOICES: '/categories/invoices',
  OPENING_HOURS: '/categories/opening-hours',
  SHIPPING_LINES: '/categories/shipping-lines',
  SLOT_ZONES: '/categories/slot-zones',
  SUBURBS: '/files/suburbs',
  DEPOTS: '/files/depots',
  TANKS: '/files/tanks',
  VESSELS: '/files/vessels',
  POST_CODES: '/files/post-codes',
  STATES: '/files/states',
  DOORS: '/files/doors',
  CONTAINERS: '/files/containers',
  SOS: '/files/sos',
  PORTS: '/files/ports',
  LINES: '/files/lines',
  DRIVERS: '/drivers',
  EMAIL_TEMPLATES: '/templates/email',
  SMS_TEMPLATES: '/templates/sms',
  TIME_SHEETS: '/categories/time-sheets',
  TIME_SLOTS: '/categories/time-slots',
  TRAILERS: '/categories/trailers',
  TRIPS: '/categories/trips',
  TRUCKS: '/categories/trucks',
  OPS_JOBS: '/jobs'
}